import * as React from "react"

import Seo from "../components/Seo"
import { Link } from "gatsby"
import PageWrapper from "../components/PageWrapper"

const TermsPage = () => (
  <PageWrapper>
    <Seo title="Terms of Use" />

    <main className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8 text-center">
      <div className="my-4">
        <h1 className="font-bold  leading-[45px] text-[1.5rem] ">
          About:
          <br />
          Welcome to the Bissett Fastener Finder. This site can be used to
          determine the compatibility of Bissett Fasteners with your Tool.{" "}
          <Link to="/brands">
            <u>Click here to get started!</u>
          </Link>
          <br />
          <br />
          Liability Disclaimer:
          <br />
          The information provided by Bissett Fasteners Ltd. (“we,” “us” or
          “our”) on http://ff.bissettfasteners.ca/ (the “site”) is for general
          informational purposes only. All information on the site is provided
          in good faith, however we make no representation or warranty of any
          kind, express or implied, regarding the accuracy adequacy, validity,
          reliability, availability, or completeness of any information on the
          site. Please take all steps necessary to ascertain that information
          you receive from our site is correct and has been verified by the tool
          manufacturer specifications. Under no circumstance shall we have any
          liability to you for any loss or damage of any kind incurred as a
          result of the use of the site or reliance on any information provided
          on the site. Your use of the site and your reliance on any information
          on the site is solely at your own risk.
          <br />
          <br />
        </h1>
      </div>
    </main>
  </PageWrapper>
)

export default TermsPage
